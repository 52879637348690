@import "~bootstrap/scss/bootstrap";
@import "vars.scss";
@import "fonts.scss";

body {
    user-select: none;
    @include flex-column;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}