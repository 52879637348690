@font-face {
  font-family: "Lullabies";
  src: url("./fonts/Lullabies/355ae919c56f5f23d85d6ca1f8543ccb.eot");
  /* IE9*/
  src: url("./fonts/Lullabies/355ae919c56f5f23d85d6ca1f8543ccb.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./fonts/Lullabies/355ae919c56f5f23d85d6ca1f8543ccb.woff2") format("woff2"),
    /* chrome、firefox */
    url("./fonts/Lullabies/355ae919c56f5f23d85d6ca1f8543ccb.woff") format("woff"),
    /* chrome、firefox */
    url("./fonts/Lullabies/355ae919c56f5f23d85d6ca1f8543ccb.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("./fonts/Lullabies/355ae919c56f5f23d85d6ca1f8543ccb.svg#Lullabies W00 Heavy") format("svg");
  /* iOS 4.1- */
}

@font-face {
  font-family: 'Helvetica Condensed';
  src: url('./fonts/Helvetica Condensed/helvetica_condensed_regular-webfont.woff2') format('woff2'),
    url('./fonts/Helvetica Condensed/helvetica_condensed_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Helvetica Condensed Bold';
  src: url('./fonts/Helvetica Condensed/helveticaneueltstd-bdcn-webfont.woff2') format('woff2'),
    url('./fonts/Helvetica Condensed/helveticaneueltstd-bdcn-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

// @font-face {
//   font-family: 'Helvetica Condensed Bold';
//   src: url('./fonts/Helvetica Condensed/helvetica_condensed_bold-webfont.woff2') format('woff2'),
//     url('./fonts/Helvetica Condensed/helvetica_condensed_bold-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }

@font-face {
  font-family: 'Helvetica Neue Condensed';
  src: url('./fonts/Helvetica Condensed/helveticaneueltstd-cn-webfont.woff2') format('woff2'),
    url('./fonts/Helvetica Condensed/helveticaneueltstd-cn-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue Condensed Thin';
  src: url('./fonts/Helvetica Condensed/helveticaneueltstd-thcn-webfont.woff2') format('woff2'),
    url('./fonts/Helvetica Condensed/helveticaneueltstd-thcn-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'verlagbold';
  src: url('./fonts/Verlag/Verlag-Bold.otf') format('opentype'),
    url('./fonts/Verlag/verlag-bold-webfont.woff2') format('woff2'),
    url('./fonts/Verlag/verlag-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'verlagbook';
  src: url('./fonts/Verlag/Verlag-Book.otf') format('opentype'),

    url('./fonts/Verlag/verlag-book-webfont.woff2') format('woff2'),
    url('./fonts/Verlag/verlag-book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'verlaglight';
  src: url('./fonts/Verlag/Verlag-Light.otf') format('opentype'),

    url('./fonts/Verlag/verlag-light-webfont.woff2') format('woff2'),
    url('./fonts/Verlag/verlag-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}