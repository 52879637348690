.loading-parent {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: white;
    display: flex;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 9000;
    transition: opacity 0.3s ease-in-out 0.2s;
    pointer-events: initial;

    & img {
        opacity: 1;
        width: 240px;
        transition: opacity 0.2s ease-in-out;
    }
}

.loaded {
    opacity: 0 !important;
    pointer-events: none !important;

    & img {
        opacity: 0 !important;
    }
}