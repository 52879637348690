@import '../../vars.scss';

.welcome {
    @include mobile-full-width;
    margin-top: 100px;


    display: flex;
    justify-content: center;
    align-items: center;

    @include for-mobile {
        @include flex-column;
        max-height: none;
        height: max-content;
        margin-top: 0;

        & p {
            margin-right: 0;
        }
    }

    &__text {
        & h1 {
            font-size: calc(49px * var(--scale));
            font-family: $title-font;
            margin-bottom: calc(20px * var(--scale));
        }

        & p {
            font-size: calc(24px * var(--scale));
            line-height: calc(36px * var(--scale));
            font-family: $paragraph-font;
            width: calc(720px* var(--scale));
            margin-bottom: 0;
            margin-top: 0;
            margin-right: calc(200px * var(--scale));

            @include for-mobile {
                margin-right: 0;
                padding-left: 20px;
            }
        }

        & h2 {
            font-family: $title-font;
            margin-top: calc(80px * var(--scale));
            font-size: calc(36px * var(--scale));
            margin-bottom: calc(20px * var(--scale));

            @include for-mobile {
                margin-right: 0;
                padding-left: 20px;
            }
        }
    }

    & img {
        margin: 0;
        margin-top: calc(120px * var(--scale));

        width: calc(371px * var(--scale) * 1.2);
        height: calc(481px* var(--scale) * 1.2);

        @include for-mobile {
            padding-bottom: 82px;
            box-sizing: content-box;
        }

    }

    & a {
        color: $pink;
    }

    margin-bottom: 80px;

}

.welcome-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    margin: 0;
    padding: 0;
    justify-content: center;
    font-size: calc(20px * var(--scale));
    font-family: $paragraph-font;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 80px;
    background-color: white;

    & img {
        height: calc(22px * var(--scale));
        margin-right: calc(14px * var(--scale));
    }

    &__mail {
        color: $pink;
        margin-right: calc(70px * var(--scale));
    }

    &__insta {
        color: $yellow;
    }

}