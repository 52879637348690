@import '../../vars.scss';

.artwork-gallery {
    display: flex;
    @include mobile-full-width;
    margin-top: $navbar-height;
    @include flex-column;

    @include for-mobile {
        margin-top: 0;
    }

    & artworks-parent {
        @include flex-column;
        margin: 0;

    }

    box-sizing: border-box;

    &>* {
        box-sizing: border-box;
    }

    display: fixed;
    width: calc(100% - $carousel-width);
    margin-left: $carousel-width;
}

.main {
    display: flex;
}