@mixin for-mobile {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin for-desktop {
    @media (min-width: 575.98px) {
        @content;
    }
}

@mixin mobile-full-width {
    @include for-mobile {
        max-width: 100vw;
        width: 100vw;
        box-sizing: border-box;
        margin-left: 0;
        margin-right: 0;
    }
}

@mixin full-width {
    max-width: 100vw;
    width: 100vw;
    box-sizing: border-box;
    margin-left: 0;
    margin-right: 0;
}

$default-width: min(100vw, 1200px);
// $default-width: 100vw;



@mixin break-words {
    /*Utilizamos hyphens para obtener la mejor partición posible*/
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    /*Y seguidamente word-wrap como graceful degradation para los navegadores que no lo soporten*/
    word-wrap: break-word;
}

@mixin flex-column {
    display: flex;
    align-items: center;
    flex-direction: column;
}

$title-font: 'Lullabies';
$paragraph-font-bold: 'verlagbold';
$paragraph-font: 'verlagbook';
$paragraph-font-thin: 'verlaglight';

:root {
    --scale: 1;
}


$default-padding: 20px;
$default-shadow: 5px 5px 7px rgba(0, 0, 0, 0.064),
2px 2px 3px rgba(0, 0, 0, 0.064);
$default-border-radius: 6px;

$mq-phone: 200px;
$mq-tablet-portrait: 768px;
$mq-tablet-landscape: 1024px;
$mq-smalllaptop: 1280px;
$mq-laptop: 1440px;
$mq-smalldesktop: 1680px;
$mq-desktop: 1920px;


@media only screen and (min-width: $mq-phone) {
    :root {
        --scale: 0.55;
    }
}

@media only screen and (min-width: $mq-tablet-portrait) {
    :root {
        --scale: 0.6;
    }
}


@media only screen and (min-width: $mq-tablet-landscape) {
    :root {
        --scale: 0.65;
    }
}

@media only screen and (min-width: $mq-smalllaptop) {
    :root {
        --scale: 0.7;
    }
}

@media only screen and (min-width: $mq-laptop) {
    :root {
        --scale: 0.75;
    }
}

@media only screen and (min-width: $mq-smalldesktop) {
    :root {
        --scale: 0.8;
    }
}

@media only screen and (min-width: $mq-desktop) {
    :root {
        --scale: 0.85;
    }
}

$navbar-height: 120px;
$carousel-width: calc(279px * var(--scale));
$carousel-img-width: calc(190px * var(--scale));
$bg-color: #f5f5f5;
$pink: #F28095;
$yellow: #F2B544;
$grey: #EBEEF2;