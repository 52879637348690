@import url('https://fonts.googleapis.com/css2?family=Labrada:wght@300;500&family=Mynerve&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&family=Tilt+Warp&display=swap');
@import '../../../vars.scss';


$artwork-size: calc(830px * var(--scale));
$artwork-margin: 50px 0px;

.artworks-parent {
    @include for-desktop {
        // margin-left: $carousel-width !important;
        // width: calc(100% - $carousel-width);
    }

    @include flex-column;
    @include mobile-full-width;


    .artwork {
        @include mobile-full-width;
        display: flex;

        padding: 0px 88px;


        @include for-mobile {
            padding: 0;
            flex-direction: column;
        }

        &:nth-child(odd) {
            @include for-desktop {
                flex-direction: row-reverse;
            }

            text-align: right;
        }



        &>* {
            padding: 0px 38px;
            margin: $artwork-margin;

            @include for-mobile {
                padding: 0;
                margin: 20px 0;
            }

            &:first-child {
                @include for-mobile {
                    margin-top: 0;
                }
            }

            &:last-child {
                @include for-mobile {
                    margin-bottom: 0;
                }
            }
        }

        &__img-parent {
            display: flex;
            justify-content: center;
            flex: 0;
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            &__img {
                max-height: $artwork-size;
                max-width: $artwork-size;
                box-shadow: $default-shadow;
                border-radius: $default-border-radius;
                @include mobile-full-width;

                @include for-mobile {
                    max-height: 2000px !important;
                }
            }

        }

        &__description {
            flex: 1 1 0;
            position: relative;
            @include mobile-full-width;
            display: flex;
            justify-content: center;
            flex-direction: column;
            // padding: 0 6em;
            font-family: $paragraph-font;

            @include for-mobile {
                padding: 20px;
                padding-bottom: 40px;
                padding-top: 0px;
            }

            &__text-original {
                font-size: calc(54px * var(--scale));
                // font-weight: bold;
                font-family: $paragraph-font-bold;
                line-height: calc(60px * var(--scale));
                white-space: pre-line;

                &>p {
                    margin-bottom: 0;
                }

                margin-bottom: calc(60px *var(--scale));
            }

            &__language {
                color: $pink;
                font-size: calc(28px * var(--scale));
                line-height: calc(35px * var(--scale));
                font-family: $paragraph-font-thin;
                margin-bottom: calc(40px *var(--scale));
            }

            &__author {
                font-size: calc(34px * var(--scale));
                line-height: calc(35px * var(--scale));
                font-family: $paragraph-font-thin;
                // font-variant: small-caps;
                margin-bottom: calc(80px *var(--scale));

                &>p {
                    margin-bottom: 0;
                }
            }

            &__text-translated {
                font-size: calc(32px * var(--scale));
                line-height: calc(32px * var(--scale));
                font-family: $paragraph-font;
                color: $yellow;

                &>p {
                    margin-bottom: 0;
                }
            }


            &__text {
                padding: 0em 1em;
                padding-top: 1.6em;
                font-size: 1.5em;
                letter-spacing: -1px;
                font-family: $paragraph-font;
                font-weight: 200;
                line-height: 1em;
                @include break-words;
                @include mobile-full-width;

                &__paragraph {
                    margin-bottom: 1.2em;
                }
            }
        }
    }
}