@import "src/vars.scss";

.language-selector {
    font-family: $paragraph-font-bold;
    font-weight: bold;
    height: fit-content;
    margin-top: $navbar-height * 0.25;
    margin-left: calc(20px * var(--scale));
    margin-right: calc(10px * var(--scale));

    & select {
        font-family: $paragraph-font-bold;
        font-weight: bold;
        font-size: calc(36px * var(--scale));
        border: none;
        outline: none;

        & option {
            font-family: $paragraph-font-bold;
            font-weight: bold;
            font-size: calc(32px * var(--scale));
        }
    }
}