@import "../../vars.scss";

.navbar {
    margin: 0;
    padding: 5px 20px;
    background-color: white;
    height: $navbar-height;
    top: 0;
    left: 0;
    position: fixed;
    width: inherit;
    z-index: 500;
    color: black;
    font-family: $title-font;
    // font-weight: bold;
    // text-transform: uppercase;

    width: calc(100% - $carousel-width);
    margin-left: $carousel-width;
    @include for-mobile {
        margin-left: 0;
        width: 100vw;
        background-color: white;
        padding: 20px;
        height: auto;
        position: relative;
    }

    & .language-selector {
        @include for-mobile {
            padding: 0;
            margin: 0;
            background-color: white;
        }
    }

    &__tabLink {
        text-decoration: none;
        margin: 14px;
        color: black;
        font-size: 24px;
        @include for-mobile {
            padding: 0;
            margin: 0;
            background-color: white;
        }

        &:hover {
            color: rgb(210, 34, 34);
        }
    }

    & img {
        width: calc(110px * var(--scale));
        display: inline;

    }


    & a {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    &__brand {
        font-size: 26px;

        & img {
            width: calc(100px * var(--scale));
            display: inline;

        }
    }


}