@import '../../vars.scss';

h1 {
    z-index: 2000;
    color: black;
    text-align: center;
    position: relative;
    margin-top: calc(45px * var(--scale));
    font-size: calc(49px * var(--scale));
    font-family: $title-font;
    display: inline-block;
    width: fit-content;

    @include for-mobile {
        margin: 0;
        padding: 0;
    }
}

.worldmap-parent {
    text-align: center;

    & div {
        position: relative;

        &>.worldmap {
            @include mobile-full-width;
            object-fit: contain;
            max-height: calc(100vh - 80px);
            width: 90vw;

            @include for-mobile {
                height: auto;
                padding-top: 30px;
            }
        }

        &>p {
            position: absolute;
            top: 80%;
            left: 0%;
            text-align: left;
            font-family: $paragraph-font;
            font-size: calc(35px * var(--scale));

            & .highlight {
                color: $yellow;
            }
        }
    }


}