@import '../../../vars.scss';

$carousel-height: 100%;

.carousel {
    @include for-mobile {
        display: none;
    }

    @include flex-column;
    background-color:$pink;
    height: $carousel-height;
    position: fixed;
    box-sizing: border-box;
    left: 0;
    justify-content: space-around;
    width: $carousel-width;

    &__container {
        max-height: calc($carousel-height);
        @include flex-column;
        overflow: hidden;
    }

    &__icon {
        height: 32px;
        color: white;
        cursor: pointer;
        margin: 10px;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    &__images {
        @include flex-column;
        position: relative;
        transition: 0.5s;

        & a {
            opacity: 0.4;
            filter: brightness(0.5) saturate(0.5);
            transition: 0.2s;

            &.active {
                opacity: 1;
                filter: brightness(1);
            }

            & img {
                width: $carousel-img-width;
                padding: 14px 0px;
                cursor: pointer;
                border-radius: $default-border-radius;
            }
        }
    }
}